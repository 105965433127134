import { getConfig } from '@/lib/get-config';
import type {
  BaseInstance,
  IBaseInstanceConfig,
  ModelName,
} from '@pigello/pigello-matrix';
import { useMemo } from 'react';

export function useConfig<Instance extends BaseInstance>(
  modelName: ModelName | null
) {
  return useMemo(() => {
    if (!modelName) {
      return {
        config: undefined,
        isLoading: false,
      };
    }
    return {
      config: getConfig<ModelName, Instance>(modelName, true),
      isLoading: false,
    };
  }, [modelName]);
}

export function useManyConfigs(modelNames?: ModelName[]) {
  const configs = useMemo(() => {
    const configArray: IBaseInstanceConfig<BaseInstance>[] = [];
    for (const modelName of modelNames ?? []) {
      const config = getConfig<ModelName, BaseInstance>(modelName, true);
      if (config) {
        configArray.push(config);
      }
    }
    return configArray;
  }, [modelNames]);

  return {
    isLoading: false,
    configs,
  };
}
