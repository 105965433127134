'use client';

import { Button } from '@/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/cn';
import { CalendarIcon } from '@heroicons/react/16/solid';
import { forwardRef, useRef } from 'react';
import type { DateValue } from 'react-aria';
import { useButton, useDatePicker } from 'react-aria';
import type { DatePickerStateOptions } from 'react-stately';
import { useDatePickerState } from 'react-stately';
import { Calendar } from './calendar';
import { DateField, type AdditionalDateFieldProps } from './date-field';
import { useForwardedRef } from './hooks/use-forward-ref';

const DateTimePicker = forwardRef<
  HTMLDivElement,
  DatePickerStateOptions<DateValue> & AdditionalDateFieldProps
>((props, forwardedRef) => {
  const ref = useForwardedRef(forwardedRef);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const state = useDatePickerState(props);
  const {
    groupProps,
    fieldProps,
    buttonProps: _buttonProps,
    dialogProps,
    calendarProps,
  } = useDatePicker({ ...props, label: 'Datum' }, state, ref);

  const { buttonProps } = useButton(_buttonProps, buttonRef);
  return (
    <div
      {...groupProps}
      ref={ref}
      className={cn(
        groupProps.className,
        'flex w-full items-center rounded-md bg-background ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2'
      )}
    >
      <DateField
        label='Datum'
        {...fieldProps}
        shouldForceLeadingZeros={true}
        hideResetButton={props.hideResetButton}
      />
      <Popover open={state.isOpen} modal>
        <PopoverTrigger asChild>
          <Button
            {...buttonProps}
            variant='outline'
            className='h-10 rounded-l-none bg-background'
            disabled={props.isDisabled}
          >
            <CalendarIcon className='size-5' />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          onInteractOutside={() => state.setOpen(false)}
          className='w-full'
        >
          <div {...dialogProps} className='space-y-3'>
            <Calendar {...calendarProps} datePickerState={state} />
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
});

DateTimePicker.displayName = 'DateTimePicker';

export { DateTimePicker };
